<template>
  <div class="text-msg-pic-upload">
    <el-upload
      :class="{ display: uploadDisabled }"
      :disabled="disabled"
      list-type="picture-card"
      ref="upload"
      action
      multiple
      :http-request="handleUpload"
      :auto-upload="autoUpload"
      :limit="limit"
      :file-list="tempFileList"
      :on-exceed="handleExceed"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :on-preview="handlePictureCardPreview"
      accept="jpg,.jpeg,.png,.bmp,webp"
    >
      <i class="el-icon-upload" v-if="tempFileList.length<this.limit"></i>
      <div slot="tip" class="el-upload__tip" v-if="tipsFlag">{{ tips }}</div>
    </el-upload>
    <el-dialog :title="previewTitle ? `${$t('edpBackendCommon.preview')} ${previewTitle}` : $t('edpBackendCommon.preview')" :visible.sync="dialogVisible" append-to-body custom-class="max-height">
      <div class="scroll-box">
        <img :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
    <!-- <div class="Upload_pictures">
      <ul class="el-upload__tip cBBBDBF" style="color: #bbbdbf">
        <li>支持PNG、JEPG格式 ，不超过2MB。</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import upload from "../../common/fileUpload.js";
export default {
  name: "UploadImageDemo",
  props: {
    width: {
      type: String,
      default: "240px",
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 9,
    },
    limitType: {
      type: Array,
      default: () => ["image/jpeg", "image/png", "image/jpg","image/webp", "image/bmp"],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imgList: {
      type: Array,
      default: () => [],
    },
    tipsFlag: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: "",
    },
    preview: {
      type: Boolean,
      default: false,
    },
    previewTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 上传文件列表，el-upload使用，临时保存数据。
      tempFileList: this.imgList,
      host: "", // 阿里云上传服务器地址根路径
      dirs: "",
      uploadDisabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  watch: {
    // 解决第二渲染接口， 图片还保留着原来的问题 JerryYi
    imgList: {
      immediate: true,
      handler(val) {
        if (this.preview) return;
        this.tempFileList = val;
      },
    },
  },
  computed: {
    upText() {
      return this.autoUpload ? "上传文件" : "选择文件";
    },
  },
  created() {},
  mounted() {},
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      // console.log('beforeUpload', file)
      let types = this.limitType;
      const isImage = types.includes(file.type);
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message({
          message:
            types.length == 0
              ? "上传图片只能是 jpg、png、bmp、jpeg、webp 格式!"
              : "上传图片只能是 jpg、png、bmp、jpeg、webp 格式!",
          type: "warning",
        });
        return false;
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
      return isImage && isLt5M;
    },
    // 自定义上传操作
    handleUpload(op) {
      let dir = `images`;
      upload(
        op.file,
        (res) => {
          let temp = {
            name: res.attachment,
            url: res.host + "/" + res.aliyunAddress,
          };
          this.host = res.host;
          this.dirs = res.dir;
          op.onSuccess(temp);
        },
        (err) => {
          console.log(err);
        },
        (res) => {
          op.onProgress(res);
        },
        dir
      );
    },
    // 上传成功后触发
    handleSuccess(response, file, fileList) {
      this.filterFileFn(fileList);
    },
    // 返回给接口要用的格式
    filterFileFn(fileList) {
      let filterArr = fileList
        .filter((item) => !item.status || item.status !== "ready") // 过滤未上传的文件
        .map((item) => {
          let url = item.response ? item.response.url : item.url;
          return {
            url: url, // item.url || item.response.url
            name: this.dirs + item.response.name,
          };
        });
       console.log('params', filterArr)
      this.$emit("onSuccessFiles", filterArr);
    },
    // 监听移除文件列表
    handleRemove(file, fileList) {
      if (file.status === "success") {
        this.filterFileFn(fileList);
      }
    },
    handleExceed(files, fileList) {
      this.$message({
        message: `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        type: "warning",
      });
    },
    beforeRemove() {
      // return this.$confirm(`确定移除 ${file.name}？`)
    },
  },
};
</script>

<style scoped>
  >>> .max-height {
    max-height: 80vh;
  }
  >>> .max-height .el-dialog__body {
    max-height: 75vh;
    
  }
  >>> .max-height .el-dialog__body .scroll-box {
    max-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  >>> .max-height .el-dialog__body .scroll-box img {
    max-height: 70vh;
  }
</style>
<style>
.text-msg-pic-upload .el-upload--picture-card,
.text-msg-pic-upload .el-upload-list--picture-card .el-upload-list__item {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.display .el-upload--picture-card {
  display: none;
}
.el-icon-upload{
  background: #EBEBEB;
}
</style>
